import React from 'react';
import { Card } from '../components/Card/Card';
import classes from './Home.module.css';
import pixelMixerPromoStill from '../assets/images/pixelmixer_promo.png';
import pixelMixerPromoGif from '../assets/images/pixelmixer_promo.gif';
// import pharmercybirdPromoStill from '../assets/images/pharmercybird_promo.png';
// import pharmercybirdPromoGif from '../assets/images/pharmercybird_promo.gif';
import cuteMixerPromoStill from '../assets/images/cutemixer_promo.png';
import cuteMixerPromoGif from '../assets/images/cutemixer_promo.gif';
// import pwaOfIceAndFirePromoStill from '../assets/images/pwaoficeandfire_promo.png'

class Home extends React.Component {
  constructor(props) {
    super(props);
    if (window.location.href.includes('overwatchpixelmixer.')) {
      window.location.href = window.location.href
        .split('overwatchpixelmixer.setup-coding.de/')
        .join('setup-coding.de/overwatchpixelmixer');
    }
    if (window.location.href.includes('overwatchcutemixer.')) {
      window.location.href = window.location.href
        .split('overwatchcutemixer.setup-coding.de/index.html')
        .join('setup-coding.de/overwatchcutemixer');
    }
  }
  componentDidMount() {
    document.title = "Setup's Projects";
  }
  // cardClickHandler = (url) => {
  // this.props.history.push(url);
  //do the routing here
  // };
  externalReroute = (href) => {
    window.location.href = href;
  };

  render() {
    return (
      <div className={classes.Home}>
        <Card
          link={'/overwatchpixelmixer'}
          img={pixelMixerPromoStill}
          title={'Overwatch Pixel Mixer'}
          description={'Mix your favorite heroes into one!'}
          gif={pixelMixerPromoGif}
          onCardClick={this.cardClickHandler}
        />
        {/* <Card
          link={"https://setup-coding.de/pharmercybird/pharmercybird.html"}
          img={pharmercybirdPromoStill}
          // title={"Pharmercy Bird (ALPHA)"}
          description={"Escape Mei's evil ice walls!"}
          gif={pharmercybirdPromoGif}
          onCardClick={this.externalReroute}
        /> */}
        <Card
          link={'/overwatchcutemixer'}
          img={cuteMixerPromoStill}
          title={'Overwatch Cute Mixer'}
          description={'Mix your own cute spray!'}
          gif={cuteMixerPromoGif}
          onCardClick={this.cardClickHandler}
        />
        {/*<Card link={"https://setup-coding.de/pwaoficeandfire/index.html"}*/}
        {/*      img={pwaOfIceAndFirePromoStill}*/}
        {/*      title={"A PWA of Ice and Fire"}*/}
        {/*      description={"Explore the Houses of Game of Thrones"}*/}
        {/*      gif={pwaOfIceAndFirePromoStill}*/}
        {/*      onCardClick={this.externalReroute}/>*/}
      </div>
    );
  }
}

export default Home;
