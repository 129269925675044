import React, { useState, useEffect } from 'react';
import MixerControl from './MixerControl/MixerControl';
import { BODY_PARTS, MixerControlsContext } from '../../containers/PixelMixer/PixelMixer';
import classes from './MixerControls.module.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

export const MixerControls = (props) => {
  // const [isVoting, setIsVoting] = useState(false);
  // const [voteCount, setVoteCount] = useState(0);
  const [currentChamp, setCurrentChamp] = useState({
    headId: 0,
    head2Id: 0,
    torsoId: 0,
    legsId: 0,
  });
  useEffect(() => {
    getVoteCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.mixerState.headParam,
    props.mixerState.head2Param,
    props.mixerState.torsoParam,
    props.mixerState.legsParam,
  ]);
  const getVoteCount = () => {
    if (
      props.mixerState.headParam &&
      props.mixerState.torsoParam &&
      (currentChamp.headId !== props.mixerState.headParam ||
        currentChamp.torsoId !== props.mixerState.torsoParam ||
        (props.mixerState.legsParam && currentChamp.legsParam !== props.mixerState.legsParam) ||
        (props.mixerState.head2Param && currentChamp.head2Param !== props.mixerState.head2Param))
    ) {
      if (props.isPixelMixer) {
        setCurrentChamp({
          headId: props.mixerState.headParam,
          torsoId: props.mixerState.torsoParam,
          legsId: props.mixerState.legsParam,
          head2Id: props.mixerState.head2Param,
        });
      } else {
        setCurrentChamp({
          headId: props.mixerState.headParam,
          torsoId: props.mixerState.torsoParam,
          head2Id: props.mixerState.head2Param,
        });
      }
      fetch(
        `https://u8xzntjbqd.execute-api.us-east-1.amazonaws.com/dev/${
          props.isPixelMixer ? 'pixel' : 'cute'
        }/votes?head=${props.mixerState.headParam}${
          props.mixerState.isAshe ? `&head2=${props.mixerState.head2Param}` : ''
        }&torso=${props.mixerState.torsoParam}${props.isPixelMixer ? `&legs=${props.mixerState.legsParam}` : ''}`
      )
        .then((response) => response.json())
        .then((data) => {
          // setVoteCount(data.votes);
        });
    }
  };

  // const onUpvote = () => {
  //   setIsVoting(true);
  //   fetch(
  //     `https://u8xzntjbqd.execute-api.us-east-1.amazonaws.com/dev/${
  //       props.isPixelMixer ? 'pixel' : 'cute'
  //     }/upvote?head=${props.mixerState.headParam}${
  //       props.mixerState.isAshe ? `&head2=${props.mixerState.head2Param}` : ''
  //     }&torso=${props.mixerState.torsoParam}${props.isPixelMixer ? `&legs=${props.mixerState.legsParam}` : ''}`
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // setIsVoting(false);
  //       let votedChamps = JSON.parse(localStorage.getItem('votedChamps'));
  //       if (!votedChamps) {
  //         votedChamps = [];
  //       }
  //       votedChamps.push({
  //         h: props.mixerState.headParam,
  //         h2: props.mixerState.head2Param,
  //         t: props.mixerState.torsoParam,
  //         l: props.mixerState.legsParam,
  //       });
  //       localStorage.setItem('votedChamps', JSON.stringify(votedChamps));
  //       setVoteCount((votecount) => votecount + 1);
  //     })
  //     .catch(() => {
  //       setIsVoting(false);
  //     });
  // };

  // const didVote = (isVoting) => {
  //   if (isVoting) {
  //     return true;
  //   }
  //   let votedChamps = JSON.parse(localStorage.getItem('votedChamps'));
  //   if (!votedChamps || votedChamps.length < 1) {
  //     return false;
  //   }
  //   return !!votedChamps.find(
  //     (votedChamp) =>
  //       votedChamp.h === props.mixerState.headParam &&
  //       votedChamp.h2 === props.mixerState.head2Param &&
  //       votedChamp.t === props.mixerState.torsoParam &&
  //       votedChamp.l === props.mixerState.legsParam
  //   );
  // };

  const reloadTwitterButton = () => {
    const tweetDiv = document.getElementById('tweetBtn');
    if (tweetDiv) {
      tweetDiv.innerHTML = '';
    } else {
      return;
    }
    const tweetAnchor = document.createElement('a');
    tweetAnchor.innerHTML = 'Tweet';
    tweetAnchor.setAttribute('href', `http://twitter.com/share?url=${encodeURIComponent(window.location.href)}`);
    tweetAnchor.setAttribute('class', 'twitter-share-button');
    tweetAnchor.setAttribute('data-url', encodeURIComponent(window.location.href));
    tweetAnchor.setAttribute('data-text', props.twitterText);
    tweetAnchor.setAttribute('data-hashtags', 'OWPixelMixer,OWCuteMixer,Overwatch');
    tweetAnchor.setAttribute('data-via', 'SetupGaming');
    tweetAnchor.setAttribute('data-size', 'large');
    tweetDiv && tweetDiv.appendChild(tweetAnchor);
    window.twttr && window.twttr.widgets && window.twttr.widgets.load();
  };

  return (
    <div className={classes.MixerControlContainer}>
      <Button onClick={props.onRandomize} variant="outlined">
        Randomize All
      </Button>
      <MixerControlsContext.Consumer>
        {(context) => {
          return (
            <React.Fragment>
              <MixerControl
                ref={context.headRef}
                changeHandler={props.onHeadChange}
                val={context.head}
                bodyPart={BODY_PARTS.HEAD}
                key={BODY_PARTS.HEAD}
              />

              {context.isAshe && (
                <MixerControl
                  ref={context.head2Ref}
                  changeHandler={props.onHead2Change}
                  val={context.head2}
                  bodyPart={BODY_PARTS.HEAD2}
                  key={BODY_PARTS.HEAD2}
                />
              )}
              <MixerControl
                ref={context.torsoRef}
                changeHandler={props.onTorsoChange}
                val={context.torso}
                bodyPart={BODY_PARTS.TORSO}
                key={BODY_PARTS.TORSO}
              />
              {props.isPixelMixer && (
                <MixerControl
                  ref={context.legsRef}
                  changeHandler={props.onLegsChange}
                  val={context.legs}
                  bodyPart={BODY_PARTS.LEGS}
                  key={BODY_PARTS.LEGS}
                />
              )}
              {/* <label className={didVote(isVoting) ? classes.upvoted : ''}>{`Upvotes: ${voteCount}`}</label> */}
              {/* <Button disabled={didVote(isVoting)} onClick={onUpvote} variant="outlined"> */}
              {/* Upvote this Mix */}
              {/* </Button> */}
              <label htmlFor={'shareLink'}>Share Link</label>
              <TextField
                id={'shareLink'}
                // label="Share Link"
                variant="outlined"
                onClick={props.onCopyToClipboard}
                inputRef={context.shareUrlInputRef}
                value={window.location.href}
                readOnly
              />
              {/* <input id={'shareLink'} ref={context.shareUrlInputRef} value={window.location.href} readOnly /> */}
              <Button onClick={props.onCopyToClipboard} variant="outlined" className={classes.copyButton}>
                Copy URL
              </Button>
              <div id="tweetBtn">{reloadTwitterButton()}</div>
            </React.Fragment>
          );
        }}
      </MixerControlsContext.Consumer>
    </div>
  );
};
