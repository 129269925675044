const sortedChampions = [
  { name: 'Ana', compValue: 'ana' },
  { name: 'Sombra', compValue: 'sombra' },
  { name: 'Bastion', compValue: 'bastion' },
  { name: 'Doomfist', compValue: 'doomfist' },
  { name: 'Genji', compValue: 'genji' },
  { name: 'McCree', compValue: 'mccree' },
  { name: 'Pharah', compValue: 'phara' },
  { name: 'Reaper', compValue: 'reaper' },
  { name: 'Soldier: 76', compValue: 'soldier' },
  { name: 'Tracer', compValue: 'tracer' },
  { name: 'Hanzo', compValue: 'hanzo' },
  { name: 'Junkrat', compValue: 'junkrat' },
  { name: 'Mei', compValue: 'mei' },
  { name: 'Torbjörn', compValue: 'torbjorn' },
  { name: 'Widowmaker', compValue: 'widowmaker' },
  { name: 'D.Va', compValue: 'dva' },
  { name: 'Orisa', compValue: 'orisa' },
  { name: 'Reinhardt', compValue: 'reinhardt' },
  { name: 'Roadhog', compValue: 'roadhog' },
  { name: 'Winston', compValue: 'winston' },
  { name: 'Zarya', compValue: 'zarya' },
  { name: 'Lúcio', compValue: 'lucio' },
  { name: 'Mercy', compValue: 'mercy' },
  { name: 'Symmetra', compValue: 'symmetra' },
  { name: 'Zenyatta', compValue: 'zenyatta' },
  { name: 'Moira', compValue: 'moira' },
  { name: 'Brigitte', compValue: 'brigitte' },
  { name: 'Wrecking Ball', compValue: 'hammond' },
  { name: 'Ashe', compValue: 'ashe' },
  { name: 'Bob', compValue: 'bob' },
  { name: 'Baptiste', compValue: 'baptiste' },
  { name: 'Sigma', compValue: 'sigma' },
  { name: 'Echo', compValue: 'echo' },
  { name: 'Junker Queen', compValue: 'jq' },
  { name: 'Kiriko', compValue: 'kiriko' },
  { name: 'Sojourn', compValue: 'sojourn' },
  { name: 'Ramattra', compValue: 'ramattra' },
].sort((a, b) => a.name.localeCompare(b.name));

export const champions = [...sortedChampions];
