import React, { Component } from 'react';
import classes from './MixerControl.module.css';
import { BODY_PARTS } from '../../../containers/PixelMixer/PixelMixer';
import reactTriggerChange from 'react-trigger-change';
import { champions } from '../../../assets/data/champions';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

class MixerControl extends Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  options = () => {
    return champions.map((c) => {
      if (
        !(
          (c.compValue === 'bob' && this.props.bodyPart === BODY_PARTS.TORSO) ||
          ((c.compValue === 'winston' || c.compValue === 'zarya' || c.compValue === 'bob' || c.compValue === 'ashe') &&
            this.props.bodyPart === BODY_PARTS.LEGS)
        )
      ) {
        return (
          <option key={c.compValue + '_' + this.props.bodyPart} value={c.compValue + '_' + this.props.bodyPart}>
            {c.name}
          </option>
        );
      }
      return null;
    });
  };

  onRandomize = () => {
    let randomValue = this.randomOptionFrom(this.selectRef.current.children[0]).value;
    if (randomValue !== this.selectRef.current.children[0].value) {
      this.selectRef.current.children[0].value = randomValue;
      reactTriggerChange(this.selectRef.current.children[0]);
    } else {
      this.onRandomize();
    }
  };
  randomOptionFrom = (select) => {
    return this.randomItemFrom(select.options);
  };
  randomItemFrom = (arr) => {
    return arr[Math.floor(Math.random() * arr.length)];
  };

  render() {
    return (
      <div className={classes.ControlGroup}>
        <label htmlFor={this.props.bodyPart}>Select {this.props.bodyPart}</label>
        {/* <select
          id={this.props.bodyPart}
          ref={this.selectRef}
          onChange={this.props.changeHandler}
          value={this.props.val + "_" + this.props.bodyPart}
        >
          
        </select> */}

        <Select
          native
          id={this.props.bodyPart}
          ref={this.selectRef}
          onChange={this.props.changeHandler}
          value={this.props.val + '_' + this.props.bodyPart}
          inputProps={{
            name: this.props.bodyPart,
            id: this.props.bodyPart,
          }}
        >
          {this.options()}
        </Select>
        {/* <button>Randomize</button> */}
        <Button onClick={this.onRandomize} variant="outlined">
          Randomize
        </Button>
      </div>
    );
  }
}

export default MixerControl;
