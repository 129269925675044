import React from 'react';

import classes from './NavigationItem.module.css';
import {NavLink} from "react-router-dom";

const navigationItem = (props) => (
    <li className={classes.NavigationItem + " nav-item"}>
        {props.social ?
            <a href={props.link} target="_blank" rel="noopener noreferrer">{props.children}</a> :
            <NavLink
                to={props.link}
                exact={props.exact}
                activeClassName={classes.active}>{props.children}</NavLink>}
    </li>


);

export default navigationItem;