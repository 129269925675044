import React, { useState, useRef, useEffect } from 'react';
import Loader from '../Loader/Loader';
import classes from './Canvas.module.css';

export const Canvas = (props) => {
  const [canvasState, setCanvasState] = useState({
    querySuccessCount: 0,
    yOffset: props.yOffset,
    xOffset: props.xOffset,
    canvas: useRef(null),
    isPixelMixer: props.isPixelMixer,
    imageSrc: null,
  });

  let querySuccessCount = 0;
  let headData = null;
  let head2Data = null;
  let torsoData = null;
  let legsData = null;
  let context = null;
  const origin = '/static/images/';

  // let context = null;
  useEffect(() => {
    setCanvasState({ ...canvasState, imageSrc: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    context = canvasState.canvas.current.getContext('2d');
    queryImage(props.headVal, false);
    queryImage(props.head2Val, false);
    queryImage(props.torsoVal, false);
    canvasState.isPixelMixer && queryImage(props.legsVal, false);
  }, [props.headVal, props.head2Val, props.torsoVal, props.legsVal]);
  // const setContext = (state) => {
  //    return setCanvasState({...state, context: state.canvas.getContext('2d')});
  // };

  const resetCanvasWidthAndHeight = () => {
    if (canvasState.canvas.current) {
      canvasState.canvas.current.width = 1500;
      canvasState.canvas.current.height = 1500;
    }
  };

  const queryImage = (champAndType, shouldDraw) => {
    let slicedChampAndType = champAndType.split('_');
    let selectedChampPart = {};
    for (let i = 0; i < props.champData.length; i++) {
      if (
        props.champData[i].id === slicedChampAndType[0] &&
        (props.champData[i].type === slicedChampAndType[1] ||
          (slicedChampAndType[1].includes('head2') && props.champData[i].type === 'head'))
      ) {
        selectedChampPart = props.champData[i];
        break;
      }
    }
    if (!shouldDraw) {
      querySuccessCount = querySuccessCount + 1;
    }
    // yourletiable = obj[0];
    if (slicedChampAndType[1] === 'head2') {
      head2Data = selectedChampPart;
    }
    if (slicedChampAndType[1] === 'head') {
      headData = selectedChampPart;
    }

    if (slicedChampAndType[1] === 'torso') {
      torsoData = selectedChampPart;
    } else if (slicedChampAndType[1] === 'legs') {
      legsData = selectedChampPart;
    }
    if (
      shouldDraw ||
      (canvasState.isPixelMixer && querySuccessCount === 4) ||
      (!canvasState.isPixelMixer && querySuccessCount === 3)
    ) {
      querySuccessCount = 0;
      redraw();
    }
  };

  const redraw = () => {
    if ((headData && torsoData) || (canvasState.isPixelMixer && legsData)) {
      const sources = [];
      sources.push(origin + headData.imgurl);
      sources.push(origin + torsoData.imgurl);
      canvasState.isPixelMixer && sources.push(origin + legsData.imgurl);
      if (props.isAsheTorso) {
        !!head2Data && sources.push(origin + head2Data.imgurl);
      }

      headData.pos = calculateHeadPosition(headData, torsoData);
      if (props.isAsheTorso) {
        head2Data.pos = !!head2Data && calculateHead2Position(head2Data, torsoData);
      }
      if (canvasState.isPixelMixer) {
        legsData.pos = calculateLegsPosition(legsData, torsoData);
      }

      const imageData = [
        {
          src: origin + headData.imgurl,
          x: headData.pos.x,
          y: headData.pos.y,
          sw: parseInt(headData.width),
          sh: parseInt(headData.height),
          suffix: headData.suffix,
        },
        {
          src: origin + torsoData.imgurl,
          x: canvasState.xOffset,
          y: canvasState.yOffset,
          sw: parseInt(torsoData.width),
          sh: parseInt(torsoData.height),
          suffix: torsoData.suffix,
        },
      ];
      canvasState.isPixelMixer &&
        imageData.push({
          src: origin + legsData.imgurl,
          x: legsData.pos.x,
          y: legsData.pos.y,
          sw: parseInt(legsData.width),
          sh: parseInt(legsData.height),
          suffix: legsData.suffix,
        });
      if (props.isAsheTorso) {
        !!head2Data &&
          imageData.push({
            src: origin + head2Data.imgurl,
            x: head2Data.pos.x,
            y: head2Data.pos.y,
            sw: parseInt(head2Data.width),
            sh: parseInt(head2Data.height),
            suffix: head2Data.suffix,
          });
      }
      // for(i = 0; i < 2; i++){
      //     imageObj.src = imgs[i].src;
      //     imageObj.onload = function() {
      //         context.drawImage(this,0,0);
      //
      // }

      loadImages(sources, (images) => {
        if (!canvasState || !canvasState.canvas || !canvasState.canvas.current) {
          return;
        }
        resetCanvasWidthAndHeight();
        if (canvasState.canvas.current) {
          context.clearRect(0, 0, canvasState.canvas.current.width, canvasState.canvas.current.height);
        }
        if (
          !canvasState.isPixelMixer &&
          (props.torsoVal === 'mccree_torso' ||
            props.torsoVal === 'zenyatta_torso' ||
            props.headVal === 'widowmaker_head' ||
            props.headVal === 'symmetra_head' ||
            props.headVal === 'dva_head' ||
            props.torsoVal === 'ramattra_torso') &&
          !(props.torsoVal === 'mccree_torso' && props.headVal === 'torbjorn_head')
        ) {
          context.drawImage(
            images[0],
            imageData[0].x,
            imageData[0].y,
            parseInt(imageData[0].sw),
            parseInt(imageData[0].sh)
          );
          context.drawImage(
            images[1],
            canvasState.xOffset,
            canvasState.yOffset,
            parseInt(imageData[1].sw),
            parseInt(imageData[1].sh)
          );
          images[2] &&
            context.drawImage(
              images[2],
              imageData[2].x,
              imageData[2].y,
              parseInt(imageData[2].sw),
              parseInt(imageData[2].sh)
            );
        } else if (
          !canvasState.isPixelMixer &&
          props.torsoVal === 'mccree_torso' &&
          props.headVal === 'torbjorn_head'
        ) {
          context.drawImage(
            images[1],
            canvasState.xOffset,
            canvasState.yOffset,
            parseInt(imageData[1].sw),
            parseInt(imageData[1].sh)
          );
          context.drawImage(
            images[0],
            imageData[0].x,
            imageData[0].y,
            parseInt(imageData[0].sw),
            parseInt(imageData[0].sh)
          );
          images[2] &&
            context.drawImage(
              images[2],
              imageData[2].x,
              imageData[2].y,
              parseInt(imageData[2].sw),
              parseInt(imageData[2].sh)
            );
        } else {
          context.drawImage(
            images[1],
            canvasState.xOffset,
            canvasState.yOffset,
            parseInt(imageData[1].sw),
            parseInt(imageData[1].sh)
          );
          context.drawImage(
            images[0],
            imageData[0].x,
            imageData[0].y,
            parseInt(imageData[0].sw),
            parseInt(imageData[0].sh)
          );
          images[2] &&
            context.drawImage(
              images[2],
              imageData[2].x,
              imageData[2].y,
              parseInt(imageData[2].sw),
              parseInt(imageData[2].sh)
            );
          images[3] &&
            context.drawImage(
              images[3],
              imageData[3].x,
              imageData[3].y,
              parseInt(imageData[3].sw),
              parseInt(imageData[3].sh)
            );
        }
        cropImageFromCanvas();

        // let img = global.context.canvas.toDataURL("image/png");
        localStorage.setItem('savedImageData', canvasState.canvas.current.toDataURL('image/png'));
        // $('#myCanvas').remove();
        // $('#generatedImage').remove();
        // $('#loading').hide();
        let image = localStorage.getItem('savedImageData');
        setCanvasState({ ...canvasState, imageSrc: image });
        // $('#canvasReplace').append('<img id="generatedImage" src="' + img + '"/>');
      });
    }
  };

  const loadImages = (sources, callback) => {
    let images = {};
    let loadedImages = 0;
    let numImages = 0;
    // get num of sources
    for (let i = 0; i < sources.length; i++) {
      numImages++;
    }
    for (let i = 0; i < sources.length; i++) {
      images[i] = new Image();
      // eslint-disable-next-line no-loop-func
      images[i].onload = () => {
        if (++loadedImages >= numImages) {
          callback(images);
        }
      };
      images[i].src = sources[i];
    }
  };

  const calculateLegsPosition = (legs, torso) => {
    let pos = {};
    pos.x = parseInt(torso.leganchorx) + canvasState.xOffset - parseInt(legs.leganchorx);
    pos.y = parseInt(torso.leganchory) + canvasState.yOffset - parseInt(legs.leganchory);
    return pos;
  };

  const calculateHeadPosition = (head, torso) => {
    let pos = {};
    pos.x = parseInt(torso.headanchorx) + canvasState.xOffset - parseInt(head.headanchorx);
    pos.y = parseInt(torso.headanchory) + canvasState.yOffset - parseInt(head.headanchory);
    return pos;
  };

  const calculateHead2Position = (head2, torso) => {
    let pos = {};
    pos.x = parseInt(torso.head2anchorx) + canvasState.xOffset - parseInt(head2.headanchorx);
    pos.y = parseInt(torso.head2anchory) + canvasState.yOffset - parseInt(head2.headanchory);
    return pos;
  };

  const cropImageFromCanvas = () => {
    if (!canvasState || !canvasState.canvas || !canvasState.canvas.current) {
      return;
    }
    let w = canvasState.canvas.current.width;
    let h = canvasState.canvas.current.height;
    let pix = { x: [], y: [] };
    const imageData = context.getImageData(0, 0, canvasState.canvas.current.width, canvasState.canvas.current.height);
    let x = 0;
    let y = 0;
    let index = 0;

    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        index = (y * w + x) * 4;
        if (imageData.data[index + 3] > 0) {
          pix.x.push(x);
          pix.y.push(y);
        }
      }
    }
    if (!pix.x.length || !pix.y.length) {
      return;
    }
    pix.x.sort(function (a, b) {
      return a - b;
    });
    pix.y.sort(function (a, b) {
      return a - b;
    });
    let n = pix.x.length - 1;

    w = pix.x[n] - pix.x[0];
    h = pix.y[n] - pix.y[0];
    let cut = context.getImageData(pix.x[0], pix.y[0], w, h);

    canvasState.canvas.current.width = w;
    canvasState.canvas.current.height = h;
    context.putImageData(cut, 0, 0);

    // let image = canvasState.canvas.current.toDataURL();
    // let win=window.open(image, '_blank');
    // win.focus();
  };

  return (
    <React.Fragment>
      <div id="canvasReplace">
        {canvasState.imageSrc ? (
          <img
            alt="generatedImage"
            className={props.isTopTen ? '' : classes.GeneratedImage}
            src={canvasState.imageSrc}
          />
        ) : (
          <Loader isTopTen={props.isTopTen} />
        )}
      </div>
      <canvas ref={canvasState.canvas} id="myCanvas" width={1500} height={1500} className={classes.Canvas} />
    </React.Fragment>
  );
};
