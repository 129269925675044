import React from "react";
import loadingImage from "../../assets/images/ringLoading.svg";
import classes from "./Loader.module.css";
const loader = props => {
  return (
    <img
      alt="loading"
      className={props.isTopTen ? "" : classes.Loader}
      src={loadingImage}
    />
  );
};

export default loader;
