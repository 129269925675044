export const champData = [
  {
    id: 'ana',
    type: 'head',
    num: 1,
    imgurl: 'cutemixer/heads/ana_head.png',
    suffix: 'A',
    width: 236,
    height: 326,
    headanchorx: 147,
    headanchory: 269,
  },
  {
    id: 'ana',
    type: 'torso',
    num: 1,
    imgurl: 'cutemixer/torsos/ana_torso.png',
    suffix: 'na',
    width: 439,
    height: 425,
    headanchorx: 148,
    headanchory: 211,
  },
  {
    id: 'bastion',
    type: 'head',
    num: 2,
    imgurl: 'cutemixer/heads/bastion_head.png',
    suffix: 'Bas',
    width: 220,
    height: 224,
    headanchorx: 101,
    headanchory: 188,
  },
  {
    id: 'bastion',
    type: 'torso',
    num: 2,
    imgurl: 'cutemixer/torsos/bastion_torso.png',
    suffix: 'tion',
    width: 512,
    height: 512,
    headanchorx: 340,
    headanchory: 229,
  },
  {
    id: 'doomfist',
    type: 'head',
    num: 3,
    imgurl: 'cutemixer/heads/doomfist_head.png',
    suffix: 'Doom',
    width: 251,
    height: 233,
    headanchorx: 111,
    headanchory: 223,
  },
  {
    id: 'doomfist',
    type: 'torso',
    num: 3,
    imgurl: 'cutemixer/torsos/doomfist_torso.png',
    suffix: 'fist',
    width: 480,
    height: 321,
    headanchorx: 318,
    headanchory: 90,
  },

  {
    id: 'orisa',
    type: 'head',
    num: 4,
    imgurl: 'cutemixer/heads/orisa_head.png',
    suffix: 'Or',
    width: 294,
    height: 205,
    headanchorx: 204,
    headanchory: 187,
  },
  {
    id: 'orisa',
    type: 'torso',
    num: 4,
    imgurl: 'cutemixer/torsos/orisa_torso.png',
    suffix: 'isa',
    width: 484,
    height: 265,
    headanchorx: 283,
    headanchory: 42,
  },
  {
    id: 'sombra',
    type: 'head',
    num: 5,
    imgurl: 'cutemixer/heads/sombra_head.png',
    suffix: 'Som',
    width: 355 / 1.3,
    height: 335 / 1.3,
    headanchorx: 236 / 1.3,
    headanchory: 293 / 1.3,
  },
  {
    id: 'sombra',
    type: 'torso',
    num: 5,
    imgurl: 'cutemixer/torsos/sombra_torso.png',
    suffix: 'bra',
    width: 245,
    height: 225,
    headanchorx: 121,
    headanchory: 52,
  },
  {
    id: 'zenyatta',
    type: 'head',
    num: 6,
    imgurl: 'cutemixer/heads/zenyatta_head.png',
    suffix: 'Zen',
    width: 292 * 0.7,
    height: 303 * 0.7,
    headanchorx: 128 * 0.7,
    headanchory: 258 * 0.7,
  },
  {
    id: 'zenyatta',
    type: 'torso',
    num: 6,
    imgurl: 'cutemixer/torsos/zenyatta_torso.png',
    suffix: 'yatta',
    width: 480,
    height: 339,
    headanchorx: 237,
    headanchory: 107,
  },
  {
    id: 'mercy',
    type: 'head',
    num: 7,
    imgurl: 'cutemixer/heads/mercy_head.png',
    suffix: 'Mer',
    width: 376,
    height: 324,
    headanchorx: 197,
    headanchory: 308,
  },
  {
    id: 'mercy',
    type: 'torso',
    num: 7,
    imgurl: 'cutemixer/torsos/mercy_torso.png',
    suffix: 'cy',
    width: 272,
    height: 176,
    headanchorx: 116,
    headanchory: 36,
  },
  {
    id: 'phara',
    type: 'head',
    num: 8,
    imgurl: 'cutemixer/heads/phara_head.png',
    suffix: 'Pha',
    width: 279,
    height: 286,
    headanchorx: 111,
    headanchory: 268,
  },
  {
    id: 'phara',
    type: 'torso',
    num: 8,
    imgurl: 'cutemixer/torsos/phara_torso.png',
    suffix: 'rah',
    width: 504,
    height: 328,
    headanchorx: 199,
    headanchory: 185,
  },
  {
    id: 'junkrat',
    type: 'head',
    num: 9,
    imgurl: 'cutemixer/heads/junkrat_head.png',
    suffix: 'Junk',
    width: 252 * 0.8,
    height: 307 * 0.8,
    headanchorx: 150 * 0.8,
    headanchory: 262 * 0.8,
  },
  {
    id: 'junkrat',
    type: 'torso',
    num: 9,
    imgurl: 'cutemixer/torsos/junkrat_torso.png',
    suffix: 'rat',
    width: 483,
    height: 457,
    headanchorx: 207,
    headanchory: 282,
  },
  {
    id: 'tracer',
    type: 'head',
    num: 10,
    imgurl: 'cutemixer/heads/tracer_head.png',
    suffix: 'Tra',
    width: 282,
    height: 299,
    headanchorx: 179,
    headanchory: 292,
  },
  {
    id: 'tracer',
    type: 'torso',
    num: 10,
    imgurl: 'cutemixer/torsos/tracer_torso.png',
    suffix: 'cer',
    width: 281,
    height: 260,
    headanchorx: 144,
    headanchory: 55,
  },
  {
    id: 'reaper',
    type: 'head',
    num: 11,
    imgurl: 'cutemixer/heads/reaper_head.png',
    suffix: 'Rea',
    width: 245,
    height: 271,
    headanchorx: 135,
    headanchory: 257,
  },
  {
    id: 'reaper',
    type: 'torso',
    num: 11,
    imgurl: 'cutemixer/torsos/reaper_torso.png',
    suffix: 'per',
    width: 507,
    height: 328,
    headanchorx: 232,
    headanchory: 149,
  },
  {
    id: 'symmetra',
    type: 'head',
    num: 12,
    imgurl: 'cutemixer/heads/symmetra_head.png',
    suffix: 'Sym',
    width: 398,
    height: 423,
    headanchorx: 160,
    headanchory: 272,
  },
  {
    id: 'symmetra',
    type: 'torso',
    num: 12,
    imgurl: 'cutemixer/torsos/symmetra_torso.png',
    suffix: 'metra',
    width: 197,
    height: 236,
    headanchorx: 62,
    headanchory: 24,
  },
  {
    id: 'widowmaker',
    type: 'head',
    num: 13,
    imgurl: 'cutemixer/heads/widowmaker_head.png',
    suffix: 'Widow',
    width: 389,
    height: 358,
    headanchorx: 228,
    headanchory: 287,
  },
  {
    id: 'widowmaker',
    type: 'torso',
    num: 13,
    imgurl: 'cutemixer/torsos/widowmaker_torso.png',
    suffix: 'maker',
    width: 204,
    height: 220,
    headanchorx: 169,
    headanchory: 5,
  },
  {
    id: 'mei',
    type: 'head',
    num: 14,
    imgurl: 'cutemixer/heads/mei_head.png',
    suffix: 'Me',
    width: 330,
    height: 333,
    headanchorx: 114,
    headanchory: 307,
  },
  {
    id: 'mei',
    type: 'torso',
    num: 14,
    imgurl: 'cutemixer/torsos/mei_torso.png',
    suffix: 'i',
    width: 307,
    height: 256,
    headanchorx: 107,
    headanchory: 61,
  },
  {
    id: 'lucio',
    type: 'head',
    num: 15,
    imgurl: 'cutemixer/heads/lucio_head.png',
    suffix: 'Lú',
    width: 504,
    height: 334,
    headanchorx: 136,
    headanchory: 305,
  },
  {
    id: 'lucio',
    type: 'torso',
    num: 15,
    imgurl: 'cutemixer/torsos/lucio_torso.png',
    suffix: 'cio',
    width: 255,
    height: 171,
    headanchorx: 44,
    headanchory: 28,
  },
  {
    id: 'mccree',
    type: 'head',
    num: 16,
    imgurl: 'cutemixer/heads/mccree_head.png',
    suffix: 'Mc ',
    width: 475,
    height: 329,
    headanchorx: 225,
    headanchory: 313,
  },
  {
    id: 'mccree',
    type: 'torso',
    num: 16,
    imgurl: 'cutemixer/torsos/mccree_torso.png',
    suffix: 'Cree',
    width: 375,
    height: 393,
    headanchorx: 99,
    headanchory: 265,
  },
  {
    id: 'roadhog',
    type: 'head',
    num: 17,
    imgurl: 'cutemixer/heads/roadhog_head.png',
    suffix: 'Road',
    width: 170 * 1.3,
    height: 200 * 1.3,
    headanchorx: 77 * 1.3,
    headanchory: 189 * 1.3,
  },
  {
    id: 'roadhog',
    type: 'torso',
    num: 17,
    imgurl: 'cutemixer/torsos/roadhog_torso.png',
    suffix: 'hog',
    width: 506,
    height: 350,
    headanchorx: 229,
    headanchory: 122,
  },
  {
    id: 'reinhardt',
    type: 'head',
    num: 18,
    imgurl: 'cutemixer/heads/reinhardt_head.png',
    suffix: 'Rein',
    width: 159 * 1.3,
    height: 209 * 1.3,
    headanchorx: 96 * 1.3,
    headanchory: 194 * 1.3,
  },
  {
    id: 'reinhardt',
    type: 'torso',
    num: 18,
    imgurl: 'cutemixer/torsos/reinhardt_torso.png',
    suffix: 'hardt',
    width: 504,
    height: 323,
    headanchorx: 199,
    headanchory: 170,
  },
  {
    id: 'hanzo',
    type: 'head',
    num: 19,
    imgurl: 'cutemixer/heads/hanzo_head.png',
    suffix: 'Han',
    width: 366,
    height: 385,
    headanchorx: 221,
    headanchory: 314,
  },
  {
    id: 'hanzo',
    type: 'torso',
    num: 19,
    imgurl: 'cutemixer/torsos/hanzo_torso.png',
    suffix: 'zo',
    width: 307,
    height: 212,
    headanchorx: 167,
    headanchory: 26,
  },
  {
    id: 'genji',
    type: 'head',
    num: 20,
    imgurl: 'cutemixer/heads/genji_head.png',
    suffix: 'Gen',
    width: 444 * 0.7,
    height: 401 * 0.7,
    headanchorx: 303 * 0.7,
    headanchory: 368 * 0.7,
  },
  {
    id: 'genji',
    type: 'torso',
    num: 20,
    imgurl: 'cutemixer/torsos/genji_torso.png',
    suffix: 'ji',
    width: 164,
    height: 183,
    headanchorx: 94,
    headanchory: 50,
  },
  {
    id: 'soldier',
    type: 'head',
    num: 21,
    imgurl: 'cutemixer/heads/soldier_head.png',
    suffix: 'Sol',
    width: 293 * 0.7,
    height: 359 * 0.7,
    headanchorx: 157 * 0.7,
    headanchory: 316 * 0.7,
  },
  {
    id: 'soldier',
    type: 'torso',
    num: 21,
    imgurl: 'cutemixer/torsos/soldier_torso.png',
    suffix: 'dier: 76',
    width: 341,
    height: 241,
    headanchorx: 125,
    headanchory: 69,
  },
  {
    id: 'torbjorn',
    type: 'head',
    num: 22,
    imgurl: 'cutemixer/heads/torbjorn_head.png',
    suffix: 'Torb',
    width: 238,
    height: 295,
    headanchorx: 149,
    headanchory: 217,
  },
  {
    id: 'torbjorn',
    type: 'torso',
    num: 22,
    imgurl: 'cutemixer/torsos/torbjorn_torso.png',
    suffix: 'jörn',
    width: 496,
    height: 375,
    headanchorx: 285,
    headanchory: 213,
  },
  {
    id: 'dva',
    type: 'head',
    num: 23,
    imgurl: 'cutemixer/heads/dva_head.png',
    suffix: 'D.',
    width: 256,
    height: 315,
    headanchorx: 128,
    headanchory: 191,
  },
  {
    id: 'dva',
    type: 'torso',
    num: 23,
    imgurl: 'cutemixer/torsos/dva_torso.png',
    suffix: 'Va',
    width: 178,
    height: 159,
    headanchorx: 96,
    headanchory: 22,
  },
  {
    id: 'zarya',
    type: 'head',
    num: 24,
    imgurl: 'cutemixer/heads/zarya_head.png',
    suffix: 'Za',
    width: 214,
    height: 269,
    headanchorx: 149,
    headanchory: 251,
  },
  {
    id: 'zarya',
    type: 'torso',
    num: 24,
    imgurl: 'cutemixer/torsos/zarya_torso.png',
    suffix: 'rya',
    width: 337,
    height: 327,
    headanchorx: 224,
    headanchory: 87,
  },
  {
    id: 'winston',
    type: 'head',
    num: 25,
    imgurl: 'cutemixer/heads/winston_head.png',
    suffix: 'Win',
    width: 137 * 1.4,
    height: 199 * 1.4,
    headanchorx: 71 * 1.4,
    headanchory: 180 * 1.4,
  },
  {
    id: 'winston',
    type: 'torso',
    num: 25,
    imgurl: 'cutemixer/torsos/winston_torso.png',
    suffix: 'ston',
    width: 503,
    height: 445,
    headanchorx: 165,
    headanchory: 324,
  },
  {
    id: 'moira',
    type: 'head',
    num: 26,
    imgurl: 'cutemixer/heads/moira_head.png',
    suffix: 'Moi',
    width: 269,
    height: 311,
    headanchorx: 126,
    headanchory: 286,
  },
  {
    id: 'moira',
    type: 'torso',
    num: 26,
    imgurl: 'cutemixer/torsos/moira_torso.png',
    suffix: 'ra',
    width: 299,
    height: 230,
    headanchorx: 140,
    headanchory: 51,
  },
  {
    id: 'brigitte',
    type: 'head',
    num: 27,
    imgurl: 'cutemixer/heads/brigitte_head.png',
    suffix: 'Bri',
    width: 342,
    height: 297,
    headanchorx: 168,
    headanchory: 242,
  },
  {
    id: 'brigitte',
    type: 'torso',
    num: 27,
    imgurl: 'cutemixer/torsos/brigitte_torso.png',
    suffix: 'gitte',
    width: 465,
    height: 288,
    headanchorx: 192,
    headanchory: 105,
  },
  {
    id: 'hammond',
    type: 'head',
    num: 28,
    imgurl: 'cutemixer/heads/hammond_head.png',
    suffix: 'Wrecking ',
    width: 294,
    height: 257,
    headanchorx: 166,
    headanchory: 251,
  },
  {
    id: 'hammond',
    type: 'torso',
    num: 28,
    imgurl: 'cutemixer/torsos/hammond_torso.png',
    suffix: ' Ball',
    width: 335,
    height: 365,
    headanchorx: 200,
    headanchory: 128,
  },
  {
    id: 'ashe',
    type: 'head',
    num: 29,
    imgurl: 'cutemixer/heads/ashe_head.png',
    suffix: 'As',
    width: 276,
    height: 195,
    headanchorx: 144,
    headanchory: 175,
  },
  {
    id: 'bob',
    type: 'head',
    num: 31,
    imgurl: 'cutemixer/heads/bob_head.png',
    suffix: 'bob',
    width: 144,
    height: 170,
    headanchorx: 83,
    headanchory: 148,
  },
  {
    id: 'ashe',
    type: 'torso',
    num: 29,
    imgurl: 'cutemixer/torsos/ashe_torso.png',
    suffix: 'he',
    width: 496,
    height: 426,
    headanchorx: 264 + 10,
    head2anchorx: 316 + 10,
    headanchory: 335 - 40,
    head2anchory: 148 - 40,
  },
  {
    id: 'baptiste',
    type: 'head',
    num: 30,
    imgurl: 'cutemixer/heads/baptiste_head.png',
    suffix: 'Bap',
    width: 259,
    height: 317,
    headanchorx: 157,
    headanchory: 290,
  },
  {
    id: 'baptiste',
    type: 'torso',
    num: 30,
    imgurl: 'cutemixer/torsos/baptiste_torso.png',
    suffix: 'tiste',
    width: 436,
    height: 380,
    headanchorx: 287,
    headanchory: 210,
  },
  {
    id: 'sigma',
    type: 'head',
    num: 32,
    imgurl: 'cutemixer/heads/sigma_head.png',
    suffix: 'Sig',
    width: 200,
    height: 262,
    headanchorx: 110,
    headanchory: 254,
  },
  {
    id: 'sigma',
    type: 'torso',
    num: 32,
    imgurl: 'cutemixer/torsos/sigma_torso.png',
    suffix: 'ma',
    width: 450,
    height: 418,
    headanchorx: 190,
    headanchory: 199,
  },
  {
    id: 'echo',
    type: 'head',
    num: 33,
    imgurl: 'cutemixer/heads/echo_head.png',
    suffix: 'Ech',
    width: 417,
    height: 306,
    headanchorx: 128,
    headanchory: 286,
  },
  {
    id: 'echo',
    type: 'torso',
    num: 33,
    imgurl: 'cutemixer/torsos/echo_torso.png',
    suffix: 'o',
    width: 375,
    height: 154,
    headanchorx: 92,
    headanchory: 16,
  },

  {
    id: 'jq',
    type: 'head',
    num: 34,
    imgurl: 'cutemixer/heads/jq_head.png',
    suffix: 'Junker ',
    width: 234,
    height: 333,
    headanchorx: 147,
    headanchory: 308,
  },
  {
    id: 'jq',
    type: 'torso',
    num: 34,
    imgurl: 'cutemixer/torsos/jq_torso.png',
    suffix: ' Queen',
    width: 300,
    height: 350,
    headanchorx: 138,
    headanchory: 179,
  },

  {
    id: 'kiriko',
    type: 'head',
    num: 35,
    imgurl: 'cutemixer/heads/kiriko_head.png',
    suffix: 'Kiri',
    width: 274,
    height: 300,
    headanchorx: 139,
    headanchory: 289,
  },
  {
    id: 'kiriko',
    type: 'torso',
    num: 35,
    imgurl: 'cutemixer/torsos/kiriko_torso.png',
    suffix: 'ko',
    width: 375,
    height: 320,
    headanchorx: 98,
    headanchory: 211,
  },

  {
    id: 'sojourn',
    type: 'head',
    num: 36,
    imgurl: 'cutemixer/heads/sojourn_head.png',
    suffix: 'So',
    width: 362,
    height: 242,
    headanchorx: 90,
    headanchory: 223,
  },
  {
    id: 'sojourn',
    type: 'torso',
    num: 36,
    imgurl: 'cutemixer/torsos/sojourn_torso.png',
    suffix: 'journ',
    width: 252,
    height: 200,
    headanchorx: 134,
    headanchory: 22,
  },
  {
    id: 'ramattra',
    type: 'head',
    num: 37,
    imgurl: 'cutemixer/heads/ramattra_head.png',
    suffix: 'Ram',
    width: 364,
    height: 260,
    headanchorx: 161,
    headanchory: 206 + 14,
  },
  {
    id: 'ramattra',
    type: 'torso',
    num: 37,
    imgurl: 'cutemixer/torsos/ramattra_torso.png',
    suffix: 'attra',
    width: 435,
    height: 231,
    headanchorx: 281,
    headanchory: 23 + 14,
  },
];
