import React, { Component } from 'react';

import classes from './Layout.module.css';
import Toolbar from '../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../components/Navigation/SideDrawer/SideDrawer';
import { withRouter } from 'react-router';
import { PATHNAMES } from '../App';

export const WebsiteContext = React.createContext({ siteName: "Setup's Projects" });
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSideDrawer: false,
      siteName: "Setup's Projects",
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let siteName;
    switch (this.props.location.pathname) {
      case PATHNAMES.HOME:
        siteName = "Setup's Projects";
        break;
      case PATHNAMES.FINANCING:
        siteName = 'Financing';
        break;
      case PATHNAMES.PIXELMIXER:
        siteName = 'Overwatch Pixel Mixer';
        break;
      case PATHNAMES.CUTEMIXER:
        siteName = 'Overwatch Cute Mixer';
        break;
      case PATHNAMES.PHARMERCYBIRD:
        siteName = 'Pharmercybird';
        break;
      default:
        siteName = "Setup's Projects";
        break;
    }
    if (this.state.siteName !== siteName) {
      this.setState({ siteName: siteName });
    }
  }

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerToggleHandler = () => {
    this.setState((prevState) => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  render() {
    return (
      <React.Fragment>
        <WebsiteContext.Provider value={{ siteName: this.state.siteName }}>
          <div className={classes.container}>
            <Toolbar drawerToggleClicked={this.sideDrawerToggleHandler} />
            <SideDrawer open={this.state.showSideDrawer} closed={this.sideDrawerClosedHandler} />
            <main className={classes.Content}>{this.props.children}</main>
          </div>
        </WebsiteContext.Provider>
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
