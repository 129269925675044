import React, { useContext } from "react";

import { WebsiteContext } from "../../hoc/Layout";
import websiteLogo from "../../assets/images/pixelatedlogo.png";
import classes from "./Logo.module.css";
import { Link } from "react-router-dom";

export const Logo = props => {
  const websiteContext = useContext(WebsiteContext);
  return (
    <Link className={classes.LogoAnchor} to="/">
      <img
        className={classes.Logo}
        src={websiteLogo}
        alt="Overwatch Pixel Mixer"
      />
      {websiteContext.siteName}
    </Link>
  );
};
