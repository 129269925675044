import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';
import redditLogo from '../../../assets/images/sc/red.png';
// import youTubeLogo from '../../../assets/images/sc/yt.png';
// import twitterLogo from '../../../assets/images/sc/twit.png';
// import facebookLogo from '../../../assets/images/sc/fb.png';

const navigationItems = (props) => (
  <ul onClick={props.clicked} className={classes.NavigationItems + ' navbar-nav mr-auto'}>
    <NavigationItem link="/" exact>
      Home
    </NavigationItem>
    {/* <NavigationItem link="/toptenpixel">Top 10 Pixel</NavigationItem> */}
    {/* <NavigationItem link="/toptencute">Top 10 Cute</NavigationItem> */}
    {/* <NavigationItem link="/financing">Financing</NavigationItem> */}
    <NavigationItem link="/links">Links</NavigationItem>
    <NavigationItem link="https://www.reddit.com/r/OverwatchPixelMixer" social={true}>
      <img src={redditLogo} alt={'Reddit'} />
    </NavigationItem>
    {/* <NavigationItem link="https://www.youtube.com/channel/UCac07H_wvYFsBHh-owGLnKA" social={true}>
      <img src={youTubeLogo} alt={'YouTube'} />
    </NavigationItem>
    <NavigationItem link="https://twitter.com/SetupGaming" social={true}>
      <img src={twitterLogo} alt={'Twitter'} />
    </NavigationItem>
    <NavigationItem link="https://www.facebook.com/SetupGaming/" social={true}>
      <img src={facebookLogo} alt={'Facebook'} />
    </NavigationItem> */}
  </ul>
);

export default navigationItems;
