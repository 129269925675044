import React from 'react';
import { NavLink } from 'react-router-dom';
import { champData } from '../../assets/data/pixel';
import buttonTryCute from '../../assets/images/button_cute.png';
import { Canvas } from '../../components/Canvas/Canvas';
import classes from './PixelMixer.module.css';
import { MixerControls } from '../../components/MixerControls/MixerControls';

export const BODY_PARTS = {
  HEAD: 'head',
  HEAD2: 'head2',
  TORSO: 'torso',
  LEGS: 'legs',
};
export const MixerControlsContext = React.createContext();
export const TWITTER_TEXTS = [
  { pre: 'Is ', post: ' a new Overwatch hero?' },
  {
    pre: 'Is ',
    post: ' the next Overwatch hero?',
  },
  {
    pre: 'I created ',
    post: '.',
  },
  {
    pre: 'Check out ',
    post: '.',
  },
  {
    pre: 'I present to you ',
    post: ' a mythical beast.',
  },
  {
    pre: 'Is ',
    post: ' the cutest Overwatch hero?',
  },
];

class PixelMixer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      isAshe: false,
      url: '',
      head: null,
      head2: null,
      torso: null,
      legs: null,
      headParam: null,
      head2Param: null,
      torsoParam: null,
      legsParam: null,
      headRef: React.createRef(),
      head2Ref: React.createRef(),
      torsoRef: React.createRef(),
      legsRef: React.createRef(),
      shareUrlInputRef: React.createRef(),
    };

    // this.mixerControlsRef = React.createRef();
  }

  componentWillMount = () => {
    //extract url params
    const query = new URLSearchParams(this.props.location.search);
    for (let param of query.entries()) {
      let champNum = +param[1];
      switch (param[0]) {
        case 'h':
          this.setState(
            () => ({
              headParam: champNum,
              head: this.findName(champNum),
            }),
            () => {
              this.updateUrlParams();
              this.extractChampName();
            }
          );
          break;
        case 'h2':
          this.setState(
            () => ({
              head2Param: champNum,
              head2: this.findName(champNum),
            }),
            () => {
              this.updateUrlParams();
              this.extractChampName();
            }
          );
          break;
        case 't':
          this.setState(
            () => ({
              torsoParam: champNum,
              torso: this.findName(champNum),
              isAshe: champNum === 29,
            }),
            () => {
              this.updateUrlParams();
              this.extractChampName();
            }
          );
          break;
        case 'l':
          this.setState(
            () => ({
              legsParam: champNum,
              legs: this.findName(champNum),
            }),
            () => {
              this.updateUrlParams();
              this.extractChampName();
            }
          );
          break;
        default:
          break;
      }
    }
  };

  componentDidMount() {
    document.title = "Overwatch Pixel Mixer - Setup's Projects";
    if (!this.props.location.search) {
      this.triggerRandomize();
    } else {
      this.extractChampName();
    }
  }

  updateUrlParams = () => {
    const urlParams = [];
    urlParams.push('h=' + this.state.headParam);
    if (this.state.isAshe) {
      urlParams.push('h2=' + this.state.head2Param);
    }
    urlParams.push('t=' + this.state.torsoParam);
    urlParams.push('l=' + this.state.legsParam);

    const queryParams = new URLSearchParams(urlParams.join('&'));
    this.props.history.push({ search: '?' + queryParams });
    this.setState({ url: queryParams });
  };

  findName = (num) => {
    const champ = champData.find((item) => item.num === num);
    return !!champ && champ.id;
  };

  findNum = (id) => {
    const champ = champData.find((item) => item.id === id);
    return !!champ && champ.num;
  };

  onTorsoChange = (event) => {
    const value = event.target.value;
    const champName = value.split('_')[0];
    if (value === 'ashe_torso') {
      this.setState(
        () => ({
          isAshe: true,
          torsoParam: this.findNum(champName),
          torso: champName,
        }),
        () => {
          this.state.head2Ref.current.onRandomize();
          this.updateUrlParams();
          this.extractChampName();
        }
      );
    } else {
      this.setState(
        () => ({
          isAshe: false,
          torsoParam: this.findNum(value.split('_')[0]),
          torso: champName,
          head2Param: null,
          head2: '',
        }),
        () => {
          this.updateUrlParams();
          this.extractChampName();
        }
      );
    }
  };
  onHeadChange = (event) => {
    const value = event.target.value;
    const champName = value.split('_')[0];
    this.setState(
      () => ({
        headParam: this.findNum(champName),
        head: champName,
      }),
      () => {
        this.updateUrlParams();
        this.extractChampName();
      }
    );
  };
  onHead2Change = (event) => {
    const value = event.target.value;
    const champName = value.split('_')[0];
    this.setState(
      () => ({
        head2Param: this.findNum(champName),
        head2: champName,
      }),
      () => {
        this.updateUrlParams();
        this.extractChampName();
      }
    );
  };
  onLegsChange = (event) => {
    const value = event.target.value;
    const champName = value.split('_')[0];
    this.setState(
      () => ({
        legsParam: this.findNum(champName),
        legs: champName,
      }),
      () => {
        this.updateUrlParams();
        this.extractChampName();
      }
    );
  };

  copyToClipboard = (event) => {
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      let el = this.state.shareUrlInputRef.current.get(0);
      let editable = el.contentEditable;
      let readOnly = el.readOnly;
      el.contentEditable = true;
      el.readOnly = false;
      let range = document.createRange();
      range.selectNodeContents(el);
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      el.setSelectionRange(0, 999999);
      el.contentEditable = editable;
      el.readOnly = readOnly;
    } else {
      this.state.shareUrlInputRef.current.select();
    }

    document.execCommand('copy');
    event.target.focus();
  };
  triggerRandomize = () => {
    this.state.headRef.current.onRandomize();
    this.state.torsoRef.current.onRandomize();
    this.state.legsRef.current.onRandomize();
  };

  extractChampName() {
    let champName = '';
    if (this.state.head2Param) {
      champName = this.getSuffixByNumAndType(this.state.headParam, BODY_PARTS.HEAD);
      champName += this.getSuffixByNumAndType(this.state.head2Param, BODY_PARTS.HEAD);
      champName += this.getSuffixByNumAndType(this.state.torsoParam, BODY_PARTS.TORSO);
      champName += this.getSuffixByNumAndType(this.state.legsParam, BODY_PARTS.LEGS);
    } else {
      champName = this.getSuffixByNumAndType(this.state.headParam, BODY_PARTS.HEAD);
      champName += this.getSuffixByNumAndType(this.state.torsoParam, BODY_PARTS.TORSO);
      champName += this.getSuffixByNumAndType(this.state.legsParam, BODY_PARTS.LEGS);
    }
    this.setState({ name: champName });
  }

  getSuffixByNumAndType(num, type) {
    const champ = champData.find((item) => item.num === num && item.type === type);
    return champ ? champ.suffix : 'Loading... ';
  }

  getTwitterText() {
    let randomText = TWITTER_TEXTS[Math.floor(Math.random() * TWITTER_TEXTS.length)];
    if (this.state.name) {
      return randomText.pre + this.state.name.toUpperCase() + randomText.post;
    }
    return '';
  }

  render() {
    return (
      <React.Fragment>
        <h4>
          <NavLink to="/overwatchcutemixer">
            <img className={classes.tryCute} src={buttonTryCute} alt="TryCuteMixer" />
          </NavLink>
        </h4>
        <div className={classes.Container}>
          <MixerControlsContext.Provider value={{ ...this.state }}>
            <MixerControls
              // ref={this.mixerControlsRef}
              isPixelMixer={true}
              onRandomize={this.triggerRandomize}
              onHeadChange={this.onHeadChange}
              onHead2Change={this.onHead2Change}
              onTorsoChange={this.onTorsoChange}
              onLegsChange={this.onLegsChange}
              onCopyToClipboard={this.copyToClipboard}
              twitterText={this.getTwitterText()}
              url={this.state.url}
              mixerState={this.state}
            />
          </MixerControlsContext.Provider>
          <div className={classes.Content}>
            <h1 className={classes.ChampName}>{this.state.name}</h1>
            <Canvas
              isAsheTorso={this.state.isAshe}
              isPixelMixer={true}
              headVal={this.state.head + '_' + BODY_PARTS.HEAD}
              head2Val={this.state.head2 + '_' + BODY_PARTS.HEAD2}
              torsoVal={this.state.torso + '_' + BODY_PARTS.TORSO}
              legsVal={this.state.legs + '_' + BODY_PARTS.LEGS}
              yOffset={150}
              xOffset={150}
              champData={champData}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PixelMixer;
