export const champData = [
  {
    id: 'ana',
    type: 'head',
    num: 1,
    imgurl: 'pixelmixer/heads/ana_head.png',
    suffix: 'A',
    width: 135,
    height: 107,
    headanchorx: 83,
    headanchory: 91,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'ana',
    type: 'legs',
    num: 1,
    imgurl: 'pixelmixer/legs/ana_legs.png',
    suffix: 'a',
    width: 237,
    height: 134,
    headanchorx: null,
    headanchory: null,
    leganchorx: 125,
    leganchory: 28,
  },
  {
    id: 'ana',
    type: 'torso',
    num: 1,
    imgurl: 'pixelmixer/torsos/ana_torso.png',
    suffix: 'n',
    width: 490,
    height: 228,
    headanchorx: 294,
    headanchory: 25,
    leganchorx: 273,
    leganchory: 87,
  },
  {
    id: 'bastion',
    type: 'head',
    num: 2,
    imgurl: 'pixelmixer/heads/bastion_head.png',
    suffix: 'Ba',
    width: 126,
    height: 118,
    headanchorx: 104,
    headanchory: 83,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'bastion',
    type: 'legs',
    num: 2,
    imgurl: 'pixelmixer/legs/bastion_legs.png',
    suffix: 'on',
    width: 288,
    height: 181,
    headanchorx: null,
    headanchory: null,
    leganchorx: 147,
    leganchory: 2,
  },
  {
    id: 'bastion',
    type: 'torso',
    num: 2,
    imgurl: 'pixelmixer/torsos/bastion_torso.png',
    suffix: 'sti',
    width: 450,
    height: 310,
    headanchorx: 285,
    headanchory: 157,
    leganchorx: 285,
    leganchory: 240,
  },
  {
    id: 'doomfist',
    type: 'head',
    num: 3,
    imgurl: 'pixelmixer/heads/doomfist_head.png',
    suffix: 'Do',
    width: 119,
    height: 149,
    headanchorx: 91,
    headanchory: 124,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'doomfist',
    type: 'legs',
    num: 3,
    imgurl: 'pixelmixer/legs/doomfist_legs.png',
    suffix: 'fist',
    width: 356,
    height: 192,
    headanchorx: null,
    headanchory: null,
    leganchorx: 153,
    leganchory: 25,
  },
  {
    id: 'doomfist',
    type: 'torso',
    num: 3,
    imgurl: 'pixelmixer/torsos/doomfist_torso.png',
    suffix: 'om',
    width: 498,
    height: 269,
    headanchorx: 244,
    headanchory: 100,
    leganchorx: 246,
    leganchory: 218,
  },
  {
    id: 'orisa',
    type: 'head',
    num: 4,
    imgurl: 'pixelmixer/heads/orisa_head.png',
    suffix: 'Or',
    width: 189,
    height: 151,
    headanchorx: 151,
    headanchory: 132,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'orisa',
    type: 'legs',
    num: 4,
    imgurl: 'pixelmixer/legs/orisa_legs.png',
    suffix: 'sa',
    width: 298,
    height: 180,
    headanchorx: null,
    headanchory: null,
    leganchorx: 82,
    leganchory: 26,
  },
  {
    id: 'orisa',
    type: 'torso',
    num: 4,
    imgurl: 'pixelmixer/torsos/orisa_torso.png',
    suffix: 'i',
    width: 445,
    height: 218,
    headanchorx: 320,
    headanchory: 69,
    leganchorx: 274,
    leganchory: 157,
  },
  {
    id: 'sombra',
    type: 'head',
    num: 5,
    imgurl: 'pixelmixer/heads/sombra_head.png',
    suffix: 'So',
    width: 165,
    height: 147,
    headanchorx: 138,
    headanchory: 124,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'sombra',
    type: 'legs',
    num: 5,
    imgurl: 'pixelmixer/legs/sombra_legs.png',
    suffix: 'ra',
    width: 285,
    height: 199,
    headanchorx: null,
    headanchory: null,
    leganchorx: 143,
    leganchory: 3,
  },
  {
    id: 'sombra',
    type: 'torso',
    num: 5,
    imgurl: 'pixelmixer/torsos/sombra_torso.png',
    suffix: 'mb',
    width: 476,
    height: 216,
    headanchorx: 293,
    headanchory: 44,
    leganchorx: 262,
    leganchory: 102,
  },
  {
    id: 'zenyatta',
    type: 'head',
    num: 6,
    imgurl: 'pixelmixer/heads/zenyatta_head.png',
    suffix: 'Zen',
    width: 122,
    height: 130,
    headanchorx: 81,
    headanchory: 101,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'zenyatta',
    type: 'legs',
    num: 6,
    imgurl: 'pixelmixer/legs/zenyatta_legs.png',
    suffix: 'ta',
    width: 245,
    height: 186,
    headanchorx: null,
    headanchory: null,
    leganchorx: 122,
    leganchory: 23,
  },
  {
    id: 'zenyatta',
    type: 'torso',
    num: 6,
    imgurl: 'pixelmixer/torsos/zenyatta_torso.png',
    suffix: 'yat',
    width: 420,
    height: 220,
    headanchorx: 217,
    headanchory: 122,
    leganchorx: 214,
    leganchory: 163,
  },
  {
    id: 'mercy',
    type: 'head',
    num: 7,
    imgurl: 'pixelmixer/heads/mercy_head.png',
    suffix: 'Me',
    width: 210,
    height: 161,
    headanchorx: 97,
    headanchory: 152,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'mercy',
    type: 'torso',
    num: 7,
    imgurl: 'pixelmixer/torsos/mercy_torso.png',
    suffix: 'rc',
    width: 500,
    height: 275,
    headanchorx: 192,
    headanchory: 121,
    leganchorx: 224,
    leganchory: 186,
  },
  {
    id: 'mercy',
    type: 'legs',
    num: 7,
    imgurl: 'pixelmixer/legs/mercy_legs.png',
    suffix: 'y',
    width: 120,
    height: 138,
    headanchorx: null,
    headanchory: null,
    leganchorx: 60,
    leganchory: 3,
  },
  {
    id: 'phara',
    type: 'head',
    num: 8,
    imgurl: 'pixelmixer/heads/phara_head.png',
    suffix: 'Pha',
    width: 121,
    height: 137,
    headanchorx: 74,
    headanchory: 123,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'phara',
    type: 'torso',
    num: 8,
    imgurl: 'pixelmixer/torsos/phara_torso.png',
    suffix: 'r',
    width: 465,
    height: 225,
    headanchorx: 163,
    headanchory: 136,
    leganchorx: 236,
    leganchory: 197,
  },
  {
    id: 'phara',
    type: 'legs',
    num: 8,
    imgurl: 'pixelmixer/legs/phara_legs.png',
    suffix: 'ah',
    width: 230,
    height: 105,
    headanchorx: null,
    headanchory: null,
    leganchorx: 66,
    leganchory: 7,
  },
  {
    id: 'junkrat',
    type: 'head',
    num: 9,
    imgurl: 'pixelmixer/heads/junkrat_head.png',
    suffix: 'Jun',
    width: 158,
    height: 166,
    headanchorx: 91,
    headanchory: 148,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'junkrat',
    type: 'torso',
    num: 9,
    imgurl: 'pixelmixer/torsos/junkrat_torso.png',
    suffix: 'k',
    width: 418,
    height: 228,
    headanchorx: 235,
    headanchory: 154,
    leganchorx: 285,
    leganchory: 195,
  },
  {
    id: 'junkrat',
    type: 'legs',
    num: 9,
    imgurl: 'pixelmixer/legs/junkrat_legs.png',
    suffix: 'rat',
    width: 232,
    height: 120,
    headanchorx: null,
    headanchory: null,
    leganchorx: 113,
    leganchory: 9,
  },
  {
    id: 'tracer',
    type: 'head',
    num: 10,
    imgurl: 'pixelmixer/heads/tracer_head.png',
    suffix: 'Tra',
    width: 197 / 1.3,
    height: 213 / 1.3,
    headanchorx: 104 / 1.3,
    headanchory: 199 / 1.3,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'tracer',
    type: 'torso',
    num: 10,
    imgurl: 'pixelmixer/torsos/tracer_torso.png',
    suffix: 'c',
    width: 385 / 1.3,
    height: 211 / 1.3,
    headanchorx: 192 / 1.3,
    headanchory: 40 / 1.3,
    leganchorx: 199 / 1.3,
    leganchory: 107 / 1.3,
  },
  {
    id: 'tracer',
    type: 'legs',
    num: 10,
    imgurl: 'pixelmixer/legs/tracer_legs.png',
    suffix: 'er',
    width: 338 / 1.3,
    height: 204 / 1.3,
    headanchorx: null,
    headanchory: null,
    leganchorx: 180 / 1.3,
    leganchory: 3 / 1.3,
  },
  {
    id: 'reaper',
    type: 'head',
    num: 11,
    imgurl: 'pixelmixer/heads/reaper_head.png',
    suffix: 'Re',
    width: 133,
    height: 150,
    headanchorx: 71,
    headanchory: 114,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'reaper',
    type: 'torso',
    num: 11,
    imgurl: 'pixelmixer/torsos/reaper_torso.png',
    suffix: 'ap',
    width: 325,
    height: 293,
    headanchorx: 206,
    headanchory: 52,
    leganchorx: 189,
    leganchory: 165,
  },
  {
    id: 'reaper',
    type: 'legs',
    num: 11,
    imgurl: 'pixelmixer/legs/reaper_legs.png',
    suffix: 'er',
    width: 93,
    height: 167,
    headanchorx: null,
    headanchory: null,
    leganchorx: 38,
    leganchory: 3,
  },
  {
    id: 'symmetra',
    type: 'head',
    num: 12,
    imgurl: 'pixelmixer/heads/symmetra_head.png',
    suffix: 'Sym',
    width: 144,
    height: 217,
    headanchorx: 68,
    headanchory: 128,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'symmetra',
    type: 'torso',
    num: 12,
    imgurl: 'pixelmixer/torsos/symmetra_torso.png',
    suffix: 'me',
    width: 366,
    height: 288,
    headanchorx: 218,
    headanchory: 108,
    leganchorx: 215,
    leganchory: 180,
  },
  {
    id: 'symmetra',
    type: 'legs',
    num: 12,
    imgurl: 'pixelmixer/legs/symmetra_legs.png',
    suffix: 'tra',
    width: 258,
    height: 175,
    headanchorx: null,
    headanchory: null,
    leganchorx: 165,
    leganchory: 20,
  },
  {
    id: 'widowmaker',
    type: 'head',
    num: 13,
    imgurl: 'pixelmixer/heads/widowmaker_head.png',
    suffix: 'Wid',
    width: 290,
    height: 285,
    headanchorx: 100,
    headanchory: 132,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'widowmaker',
    type: 'torso',
    num: 13,
    imgurl: 'pixelmixer/torsos/widowmaker_torso.png',
    suffix: 'ow',
    width: 385,
    height: 161,
    headanchorx: 273,
    headanchory: 78,
    leganchorx: 261,
    leganchory: 158,
  },
  {
    id: 'widowmaker',
    type: 'legs',
    num: 13,
    imgurl: 'pixelmixer/legs/widowmaker_legs.png',
    suffix: 'maker',
    width: 230,
    height: 175,
    headanchorx: null,
    headanchory: null,
    leganchorx: 111,
    leganchory: 6,
  },
  {
    id: 'mei',
    type: 'head',
    num: 14,
    imgurl: 'pixelmixer/heads/mei_head.png',
    suffix: 'M',
    width: 134,
    height: 135,
    headanchorx: 60,
    headanchory: 121,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'mei',
    type: 'torso',
    num: 14,
    imgurl: 'pixelmixer/torsos/mei_torso.png',
    suffix: 'e',
    width: 418,
    height: 184,
    headanchorx: 275,
    headanchory: 83,
    leganchorx: 256,
    leganchory: 168,
  },
  {
    id: 'mei',
    type: 'legs',
    num: 14,
    imgurl: 'pixelmixer/legs/mei_legs.png',
    suffix: 'i',
    width: 265,
    height: 113,
    headanchorx: null,
    headanchory: null,
    leganchorx: 129,
    leganchory: 14,
  },
  {
    id: 'lucio',
    type: 'head',
    num: 15,
    imgurl: 'pixelmixer/heads/lucio_head.png',
    suffix: 'Lú',
    width: 260,
    height: 305,
    headanchorx: 74,
    headanchory: 291,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'lucio',
    type: 'torso',
    num: 15,
    imgurl: 'pixelmixer/torsos/lucio_torso.png',
    suffix: 'ci',
    width: 383,
    height: 173,
    headanchorx: 174,
    headanchory: 46,
    leganchorx: 219,
    leganchory: 109,
  },
  {
    id: 'lucio',
    type: 'legs',
    num: 15,
    imgurl: 'pixelmixer/legs/lucio_legs.png',
    suffix: 'o',
    width: 305,
    height: 185,
    headanchorx: null,
    headanchory: null,
    leganchorx: 129,
    leganchory: 36,
  },
  {
    id: 'mccree',
    type: 'head',
    num: 16,
    imgurl: 'pixelmixer/heads/mccree_head.png',
    suffix: 'Mc ',
    width: 276,
    height: 175,
    headanchorx: 152,
    headanchory: 154,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'mccree',
    type: 'torso',
    num: 16,
    imgurl: 'pixelmixer/torsos/mccree_torso.png',
    suffix: 'cr',
    width: 448,
    height: 196,
    headanchorx: 232,
    headanchory: 30,
    leganchorx: 217,
    leganchory: 115,
  },
  {
    id: 'mccree',
    type: 'legs',
    num: 16,
    imgurl: 'pixelmixer/legs/mccree_legs.png',
    suffix: 'ee',
    width: 411,
    height: 172,
    headanchorx: null,
    headanchory: null,
    leganchorx: 188,
    leganchory: 4,
  },
  {
    id: 'roadhog',
    type: 'head',
    num: 17,
    imgurl: 'pixelmixer/heads/roadhog_head.png',
    suffix: 'Ro',
    width: 273,
    height: 170,
    headanchorx: 110,
    headanchory: 157,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'roadhog',
    type: 'torso',
    num: 17,
    imgurl: 'pixelmixer/torsos/roadhog_torso.png',
    suffix: 'ad',
    width: 442,
    height: 378,
    headanchorx: 247,
    headanchory: 147,
    leganchorx: 207,
    leganchory: 334,
  },
  {
    id: 'roadhog',
    type: 'legs',
    num: 17,
    imgurl: 'pixelmixer/legs/roadhog_legs.png',
    suffix: 'hog',
    width: 289,
    height: 92,
    headanchorx: null,
    headanchory: null,
    leganchorx: 127,
    leganchory: 32,
  },
  {
    id: 'reinhardt',
    type: 'head',
    num: 18,
    imgurl: 'pixelmixer/heads/reinhardt_head.png',
    suffix: 'Re',
    width: 111,
    height: 173,
    headanchorx: 74,
    headanchory: 159,
    // headanchorx: 89,
    // headanchory: 160,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'reinhardt',
    type: 'torso',
    num: 18,
    imgurl: 'pixelmixer/torsos/reinhardt_torso.png',
    suffix: 'in',
    width: 499,
    height: 318,
    headanchorx: 287,
    headanchory: 94,
    leganchorx: 292,
    leganchory: 185,
  },
  {
    id: 'reinhardt',
    type: 'legs',
    num: 18,
    imgurl: 'pixelmixer/legs/reinhardt_legs.png',
    suffix: 'hardt',
    width: 204,
    height: 133,
    headanchorx: null,
    headanchory: null,
    leganchorx: 90,
    leganchory: 12,
  },
  {
    id: 'hanzo',
    type: 'head',
    num: 19,
    imgurl: 'pixelmixer/heads/hanzo_head.png',
    suffix: 'Ha',
    width: 178,
    height: 151,
    headanchorx: 65,
    headanchory: 143,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'hanzo',
    type: 'torso',
    num: 19,
    imgurl: 'pixelmixer/torsos/hanzo_torso.png',
    suffix: 'n',
    width: 491,
    height: 355,
    headanchorx: 334,
    headanchory: 175,
    leganchorx: 305,
    leganchory: 248,
  },
  {
    id: 'hanzo',
    type: 'legs',
    num: 19,
    imgurl: 'pixelmixer/legs/hanzo_legs.png',
    suffix: 'zo',
    width: 339,
    height: 158,
    headanchorx: null,
    headanchory: null,
    leganchorx: 176,
    leganchory: 24,
  },
  {
    id: 'genji',
    type: 'head',
    num: 20,
    imgurl: 'pixelmixer/heads/genji_head.png',
    suffix: 'Ge',
    width: 232 * 1.3,
    height: 93 * 1.3,
    headanchorx: 54 * 1.3,
    headanchory: 77 * 1.3,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'genji',
    type: 'torso',
    num: 20,
    imgurl: 'pixelmixer/torsos/genji_torso.png',
    suffix: 'n',
    width: 491 * 1.3,
    height: 144 * 1.3,
    headanchorx: 225 * 1.3,
    headanchory: 29 * 1.3,
    leganchorx: 213 * 1.3,
    leganchory: 73 * 1.3,
  },
  {
    id: 'genji',
    type: 'legs',
    num: 20,
    imgurl: 'pixelmixer/legs/genji_legs.png',
    suffix: 'ji',
    width: 225 * 1.3,
    height: 89 * 1.3,
    headanchorx: null,
    headanchory: null,
    leganchorx: 122 * 1.3,
    leganchory: 5 * 1.3,
  },
  {
    id: 'soldier',
    type: 'head',
    num: 21,
    imgurl: 'pixelmixer/heads/soldier_head.png',
    suffix: 'Sol',
    width: 132,
    height: 137,
    headanchorx: 62,
    headanchory: 111,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'soldier',
    type: 'torso',
    num: 21,
    imgurl: 'pixelmixer/torsos/soldier_torso.png',
    suffix: 'di',
    width: 325,
    height: 143,
    headanchorx: 208,
    headanchory: 26,
    leganchorx: 279,
    leganchory: 135,
  },
  {
    id: 'soldier',
    type: 'legs',
    num: 21,
    imgurl: 'pixelmixer/legs/soldier_legs.png',
    suffix: 'er: 76',
    width: 297,
    height: 137,
    headanchorx: null,
    headanchory: null,
    leganchorx: 138,
    leganchory: 5,
  },
  {
    id: 'torbjorn',
    type: 'head',
    num: 22,
    imgurl: 'pixelmixer/heads/torbjorn_head.png',
    suffix: 'Tor',
    width: 235,
    height: 203,
    headanchorx: 140,
    headanchory: 100,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'torbjorn',
    type: 'torso',
    num: 22,
    imgurl: 'pixelmixer/torsos/torbjorn_torso.png',
    suffix: 'bj',
    width: 477,
    height: 310,
    headanchorx: 252,
    headanchory: 147,
    leganchorx: 236,
    leganchory: 223,
  },
  {
    id: 'torbjorn',
    type: 'legs',
    num: 22,
    imgurl: 'pixelmixer/legs/torbjorn_legs.png',
    suffix: 'orn',
    width: 288,
    height: 99,
    headanchorx: null,
    headanchory: null,
    leganchorx: 138,
    leganchory: 5,
  },
  {
    id: 'dva',
    type: 'head',
    num: 23,
    imgurl: 'pixelmixer/heads/dva_head.png',
    suffix: 'D.',
    width: 89,
    height: 82,
    headanchorx: 49,
    headanchory: 72,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'dva',
    type: 'torso',
    num: 23,
    imgurl: 'pixelmixer/torsos/dva_torso.png',
    suffix: 'V',
    width: 477,
    height: 286,
    headanchorx: 170,
    headanchory: 206,
    leganchorx: 228,
    leganchory: 261,
  },
  {
    id: 'dva',
    type: 'legs',
    num: 23,
    imgurl: 'pixelmixer/legs/dva_legs.png',
    suffix: 'a',
    width: 240,
    height: 154,
    headanchorx: null,
    headanchory: null,
    leganchorx: 131,
    leganchory: 31,
  },
  {
    id: 'zarya',
    type: 'head',
    num: 24,
    imgurl: 'pixelmixer/heads/zarya_head.png',
    suffix: 'Za',
    width: 135,
    height: 117,
    headanchorx: 70,
    headanchory: 116,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'zarya',
    type: 'torso',
    num: 24,
    imgurl: 'pixelmixer/torsos/zarya_torso.png',
    suffix: 'ry',
    width: 441,
    height: 234,
    headanchorx: 319,
    headanchory: 37,
    leganchorx: 270,
    leganchory: 178,
  },
  {
    id: 'zarya',
    type: 'legs',
    num: 24,
    imgurl: 'pixelmixer/legs/zarya_legs.png',
    suffix: 'a',
    width: 365,
    height: 133,
    headanchorx: null,
    headanchory: null,
    leganchorx: 184,
    leganchory: 21,
  },
  {
    id: 'winston',
    type: 'head',
    num: 25,
    imgurl: 'pixelmixer/heads/winston_head.png',
    suffix: 'Win',
    width: 143,
    height: 191,
    headanchorx: 94,
    headanchory: 182,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'winston',
    type: 'torso',
    num: 25,
    imgurl: 'pixelmixer/torsos/winston_torso.png',
    suffix: 'ston',
    width: 440,
    height: 316,
    headanchorx: 169,
    headanchory: 170,
    leganchorx: 221,
    leganchory: 238,
  },
  {
    id: 'moira',
    type: 'head',
    num: 26,
    imgurl: 'pixelmixer/heads/moira_head.png',
    suffix: 'Mo',
    width: 114,
    height: 145,
    headanchorx: 52,
    headanchory: 133,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'moira',
    type: 'torso',
    num: 26,
    imgurl: 'pixelmixer/torsos/moira_torso.png',
    suffix: 'i',
    width: 370,
    height: 334,
    headanchorx: 191,
    headanchory: 122,
    leganchorx: 179,
    leganchory: 203,
  },
  {
    id: 'moira',
    type: 'legs',
    num: 26,
    imgurl: 'pixelmixer/legs/moira_legs.png',
    suffix: 'ra',
    width: 157,
    height: 237,
    headanchorx: null,
    headanchory: null,
    leganchorx: 67,
    leganchory: 2,
  },
  {
    id: 'brigitte',
    type: 'head',
    num: 27,
    imgurl: 'pixelmixer/heads/brigitte_head.png',
    suffix: 'Bri',
    width: 244,
    height: 153,
    headanchorx: 81,
    headanchory: 123,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'brigitte',
    type: 'torso',
    num: 27,
    imgurl: 'pixelmixer/torsos/brigitte_torso.png',
    suffix: 'git',
    width: 463,
    height: 239,
    headanchorx: 277,
    headanchory: 110,
    leganchorx: 266,
    leganchory: 185,
  },
  {
    id: 'brigitte',
    type: 'legs',
    num: 27,
    imgurl: 'pixelmixer/legs/brigitte_legs.png',
    suffix: 'te',
    width: 310,
    height: 170,
    headanchorx: null,
    headanchory: null,
    leganchorx: 105,
    leganchory: 24,
  },
  {
    id: 'hammond',
    type: 'head',
    num: 28,
    imgurl: 'pixelmixer/heads/hammond_head.png',
    suffix: 'Wreck',
    width: 136,
    height: 106,
    headanchorx: 72,
    headanchory: 103,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'hammond',
    type: 'torso',
    num: 28,
    imgurl: 'pixelmixer/torsos/hammond_torso.png',
    suffix: 'ing',
    width: 413,
    height: 354,
    headanchorx: 251,
    headanchory: 27,
    leganchorx: 205,
    leganchory: 277,
  },
  {
    id: 'hammond',
    type: 'legs',
    num: 28,
    imgurl: 'pixelmixer/legs/hammond_legs.png',
    suffix: ' Ball',
    width: 472,
    height: 165,
    headanchorx: null,
    headanchory: null,
    leganchorx: 208,
    leganchory: 39,
  },
  {
    id: 'ashe',
    type: 'head',
    num: 29,
    imgurl: 'pixelmixer/heads/ashe_head.png',
    suffix: 'a',
    width: 117,
    height: 75,
    headanchorx: 60,
    headanchory: 67,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'bob',
    type: 'head',
    num: 31,
    imgurl: 'pixelmixer/heads/bob_head.png',
    suffix: 'bob',
    width: 91,
    height: 100,
    headanchorx: 65,
    headanchory: 85,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'ashe',
    type: 'torso',
    num: 29,
    imgurl: 'pixelmixer/torsos/ashe_torso.png',
    suffix: 'sh',
    width: 479,
    height: 286,
    headanchorx: 215,
    head2anchorx: 341,
    headanchory: 85,
    head2anchory: 150,
    leganchorx: 335,
    leganchory: 202,
  },
  {
    id: 'baptiste',
    type: 'head',
    num: 30,
    imgurl: 'pixelmixer/heads/baptiste_head.png',
    suffix: 'Bap',
    width: 106,
    height: 132,
    headanchorx: 71,
    headanchory: 110,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'baptiste',
    type: 'torso',
    num: 30,
    imgurl: 'pixelmixer/torsos/baptiste_torso.png',
    suffix: 'tis',
    width: 269,
    height: 284,
    headanchorx: 149,
    headanchory: 150,
    leganchorx: 167,
    leganchory: 224,
  },
  {
    id: 'baptiste',
    type: 'legs',
    num: 30,
    imgurl: 'pixelmixer/legs/baptiste_legs.png',
    suffix: 'te',
    width: 225,
    height: 254,
    headanchorx: null,
    headanchory: null,
    leganchorx: 101,
    leganchory: 3,
  },
  {
    id: 'sigma',
    type: 'head',
    num: 32,
    imgurl: 'pixelmixer/heads/sigma_head.png',
    suffix: 'Si',
    width: 99,
    height: 114,
    headanchorx: 60,
    headanchory: 93,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'sigma',
    type: 'torso',
    num: 32,
    imgurl: 'pixelmixer/torsos/sigma_torso.png',
    suffix: 'g',
    width: 415,
    height: 241,
    headanchorx: 231,
    headanchory: 36,
    leganchorx: 188,
    leganchory: 179,
  },
  {
    id: 'sigma',
    type: 'legs',
    num: 32,
    imgurl: 'pixelmixer/legs/sigma_legs.png',
    suffix: 'ma',
    width: 169,
    height: 230,
    headanchorx: null,
    headanchory: null,
    leganchorx: 55,
    leganchory: 15,
  },
  {
    id: 'echo',
    type: 'head',
    num: 33,
    imgurl: 'pixelmixer/heads/echo_head.png',
    suffix: 'E',
    width: 179,
    height: 190,
    headanchorx: 68,
    headanchory: 160,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'echo',
    type: 'torso',
    num: 33,
    imgurl: 'pixelmixer/torsos/echo_torso.png',
    suffix: 'ch',
    width: 456,
    height: 220,
    headanchorx: 297,
    headanchory: 9,
    leganchorx: 302,
    leganchory: 85,
  },
  {
    id: 'echo',
    type: 'legs',
    num: 33,
    imgurl: 'pixelmixer/legs/echo_legs.png',
    suffix: 'o',
    width: 157,
    height: 234,
    headanchorx: null,
    headanchory: null,
    leganchorx: 127,
    leganchory: 1,
  },

  {
    id: 'jq',
    type: 'head',
    num: 34,
    imgurl: 'pixelmixer/heads/jq_head.png',
    suffix: 'Junker ',
    width: 136,
    height: 173,
    headanchorx: 71,
    headanchory: 148,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'jq',
    type: 'torso',
    num: 34,
    imgurl: 'pixelmixer/torsos/jq_torso.png',
    suffix: 'Que',
    width: 512,
    height: 269,
    headanchorx: 252,
    headanchory: 150,
    leganchorx: 296,
    leganchory: 208,
  },
  {
    id: 'jq',
    type: 'legs',
    num: 34,
    imgurl: 'pixelmixer/legs/jq_legs.png',
    suffix: 'en',
    width: 280,
    height: 163,
    headanchorx: null,
    headanchory: null,
    leganchorx: 165,
    leganchory: 17,
  },

  {
    id: 'kiriko',
    type: 'head',
    num: 35,
    imgurl: 'pixelmixer/heads/kiriko_head.png',
    suffix: 'Ki',
    width: 170,
    height: 153,
    headanchorx: 78,
    headanchory: 150,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'kiriko',
    type: 'torso',
    num: 35,
    imgurl: 'pixelmixer/torsos/kiriko_torso.png',
    suffix: 'ri',
    width: 335,
    height: 93,
    headanchorx: 155,
    headanchory: 28,
    leganchorx: 167,
    leganchory: 91,
  },
  {
    id: 'kiriko',
    type: 'legs',
    num: 35,
    imgurl: 'pixelmixer/legs/kiriko_legs.png',
    suffix: 'ko',
    width: 425,
    height: 185,
    headanchorx: null,
    headanchory: null,
    leganchorx: 254,
    leganchory: 48,
  },

  {
    id: 'sojourn',
    type: 'head',
    num: 36,
    imgurl: 'pixelmixer/heads/sojourn_head.png',
    suffix: 'So',
    width: 185,
    height: 153,
    headanchorx: 58,
    headanchory: 146,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'sojourn',
    type: 'torso',
    num: 36,
    imgurl: 'pixelmixer/torsos/sojourn_torso.png',
    suffix: 'jo',
    width: 360,
    height: 119,
    headanchorx: 274,
    headanchory: 24,
    leganchorx: 265,
    leganchory: 97,
  },
  {
    id: 'sojourn',
    type: 'legs',
    num: 36,
    imgurl: 'pixelmixer/legs/sojourn_legs.png',
    suffix: 'urn',
    width: 245,
    height: 108,
    headanchorx: null,
    headanchory: null,
    leganchorx: 160,
    leganchory: 6,
  },

  {
    id: 'ramattra',
    type: 'head',
    num: 37,
    imgurl: 'pixelmixer/heads/ramattra_head.png',
    suffix: 'Ra',
    width: 176,
    height: 113,
    headanchorx: 78,
    headanchory: 104,
    leganchorx: null,
    leganchory: null,
  },
  {
    id: 'ramattra',
    type: 'torso',
    num: 37,
    imgurl: 'pixelmixer/torsos/ramattra_torso.png',
    suffix: 'mat',
    width: 412,
    height: 360,
    headanchorx: 196,
    headanchory: 153,
    leganchorx: 175,
    leganchory: 240,
  },
  {
    id: 'ramattra',
    type: 'legs',
    num: 37,
    imgurl: 'pixelmixer/legs/ramattra_legs.png',
    suffix: 'tra',
    width: 189,
    height: 134,
    headanchorx: null,
    headanchory: null,
    leganchorx: 80,
    leganchory: 16,
  },
];
