import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import CuteMixer from './containers/CuteMixer/CuteMixer';
// import classes from './App.css';
// import Financing from './containers/Financing/Financing';
import Home from './containers/Home';
import { Links } from './containers/Links/Links';
import PixelMixer from './containers/PixelMixer/PixelMixer';
import Layout from './hoc/Layout';

export const PATHNAMES = {
  HOME: '/',
  // FINANCING: '/financing',
  PIXELMIXER: '/overwatchpixelmixer',
  // TOPTENPIXEL: '/toptenpixel',
  // TOPTENCUTE: '/toptencute',
  CUTEMIXER: '/overwatchcutemixer',
  LINKS: '/links',
};

class App extends Component {
  render() {
    return (
      <>
        <Switch>
          <Route path={PATHNAMES.LINKS} component={Links} />
          <Layout>
            {/*<Route path="/pharmercybird" component={Pharmercybird}/>*/}
            {/* <Route exact path={PATHNAMES.FINANCING} component={Financing} /> */}
            <Route exact path={PATHNAMES.PIXELMIXER} component={PixelMixer} />
            {/* <Route exact path={PATHNAMES.TOPTENCUTE} component={TopCute} /> */}
            {/* <Route exact path={PATHNAMES.TOPTENPIXEL} component={TopPixel} /> */}
            <Route exact path={PATHNAMES.CUTEMIXER} component={CuteMixer} />
            <Route exact path={PATHNAMES.HOME} component={Home} />
          </Layout>
        </Switch>
      </>
    );
  }
}

export default App;
