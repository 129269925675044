import React from 'react';
import classes from './Card.module.css';
import { Link } from 'react-router-dom';

export const Card = ({ link, img, gif, title, description }) => {
  return (
    <Link to={link} className={classes.Card}>
      <img
        src={img}
        onMouseOver={(e) => (e.currentTarget.src = gif)}
        onMouseOut={(e) => (e.currentTarget.src = img)}
        alt={title}
      />
      <div>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </Link>
  );
};
